import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Avatar, Button, Dropdown, FloatButton, Layout, Modal, Popover, Select, Space } from 'antd';
import { BookOutlined, CommentOutlined, CustomerServiceOutlined, GithubOutlined, GlobalOutlined, MailOutlined, MenuOutlined, PayCircleOutlined, QrcodeOutlined, RiseOutlined, VerticalAlignTopOutlined, CloseOutlined } from '@ant-design/icons';
import { useGlobalStore } from '@/store'

import DashboardMenu from './menu';
import styles from './index.module.scss';
import GroupQrCode from '@/components/Feedback/GroupQRCode';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import React from 'react';
import ReactDOM from 'react-dom';

const { Header, Content } = Layout;

export const TransparentHeader = ({
  unselected,
  setUnselected,
  onMenuSelect,
  className,
  theme = 'dark' as 'dark' | 'light'
}: {
  unselected: boolean;
  setUnselected: (value: boolean) => void;
  onMenuSelect: () => void;
  className?: string;
  theme?: 'dark' | 'light';
}) => {
  const { t } = useTranslation();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname.split('/')[1] || '';
  
  // 定义动画和自定义样式
  const customStyles = `
    @keyframes fadeIn {
      from { opacity: 0; transform: translateY(10px); }
      to { opacity: 1; transform: translateY(0); }
    }
    @keyframes slideIn {
      from { transform: translateX(100%); }
      to { transform: translateX(0); }
    }
    
    .shadow-glow-light {
      box-shadow: 0 0 15px rgba(255, 255, 255, 0.2);
    }
    
    .shadow-glow-dark {
      box-shadow: 0 0 15px rgba(74, 108, 247, 0.3);
    }

    .menu-item {
      position: relative;
      transition: all 0.2s;
    }
    
    .menu-item:after {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 50%;
      width: 0;
      height: 2px;
      background: linear-gradient(90deg, #4A6CF7, #8A74FF);
      transition: all 0.3s ease;
      transform: translateX(-50%);
      opacity: 0;
    }
    
    .menu-item:hover:after, .menu-item.active:after {
      width: 70%;
      opacity: 1;
    }
  `;

  // 导航菜单项
  const menuItems = [
    { key: '', label: t('Home') },
    { key: 'explore', label: t('Explore') },
    { key: 'help', label: t('Documentations'), external: true, url: '/help' },
    { key: 'member', label: t('Recharge'), external: true, url: '/space/member' }
  ];

  const handleMenuClick = (path, external = false, url = '') => {
    if (external && url) {
      window.open(url, '_blank');
      return;
    }
    navigate(`/${path}`);
    setUnselected(false);
    onMenuSelect();
  };
  
  // 提前定义userLoggedIn以避免条件hooks
  const userLoggedIn = !!useGlobalStore((state) => state.currentUser?.publicId);
  
  return (
    <>
      <style>{customStyles}</style>
      <div className={`fixed top-0 left-0 right-0 z-50 flex items-center justify-between px-6 py-3 transition-all duration-300 backdrop-blur-md ${
        theme === 'dark' 
          ? 'bg-black/30 border-b border-white/5' 
          : 'bg-white/80 border-b border-gray-200/20'
      } ${className}`}>
        {/* Left Section */}
        <div className="flex items-center space-x-6">
          {/* Logo */}
          <div 
            className="w-12 h-12 bg-no-repeat bg-center bg-contain cursor-pointer" 
            style={{backgroundImage: 'url(https://ablula.oss-accelerate.aliyuncs.com/malette/assets/logo.png!xs)'}}
            onClick={() => navigate('/')}
          />
          
          {/* Main Navigation - Elegant Menu */}
          <div className="hidden md:flex items-center space-x-8">
            {menuItems.map(item => (
              <button
                key={item.key}
                onClick={() => handleMenuClick(item.key, item.external, item.url)}
                className={`relative group text-base font-medium transition-all duration-200 ${
                  (currentPath === item.key || (item.key === '' && currentPath === ''))
                    ? theme === 'dark'
                      ? 'text-white'
                      : 'text-blue-600'
                    : theme === 'dark'
                      ? 'text-white/80 hover:text-white'
                      : 'text-gray-600 hover:text-blue-600'
                }`}
              >
                {item.label}
                <span className={`absolute -bottom-1 left-0 h-0.5 bg-gradient-to-r from-blue-500 to-indigo-600 transition-all duration-300 ${
                  (currentPath === item.key || (item.key === '' && currentPath === ''))
                    ? 'w-full'
                    : 'w-0 group-hover:w-full'
                }`}></span>
              </button>
            ))}
          </div>
        </div>
        
        {/* Right Section */}
        <div className="flex items-center space-x-4">
          {/* Language Switcher */}
          <HeaderLanguageSwitch theme={theme} />
          
          {/* Dashboard Button - Next to User Avatar with Dropdown */}
          {userLoggedIn && (
            <div className="relative group">
              <button
                className={`hidden sm:flex items-center px-3 py-1.5 rounded-full text-sm font-medium transition-all duration-200 ${
                  currentPath === 'studio' || currentPath === 'comfyui' || currentPath === 'workflow'
                    ? theme === 'dark'
                      ? 'bg-white/10 text-white border border-white/20'
                      : 'bg-blue-50 text-blue-600 border border-blue-200'
                    : theme === 'dark'
                      ? 'text-white/90 hover:bg-white/5 hover:border-white/10 hover:text-white border border-transparent'
                      : 'text-gray-700 hover:bg-gray-50 hover:border-gray-200 hover:text-blue-600 border border-transparent'
                }`}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                </svg>
                <span className="mr-1">{t('Dashboard')}</span>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </button>
              
              {/* Dashboard Dropdown */}
              <div className="absolute right-0 mt-1 w-40 origin-top-right rounded-md shadow-lg z-10 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200">
                <div className={`py-1 rounded-md ring-1 ring-black/5 ${
                  theme === 'dark' ? 'bg-gray-800' : 'bg-white'
                }`}>
                  <button
                    onClick={() => navigate('/comfyui')}
                    className={`block w-full text-left px-4 py-2 ${
                      currentPath === 'comfyui'
                        ? theme === 'dark'
                          ? 'bg-gray-700 text-white'
                          : 'bg-gray-100 text-blue-600'
                        : theme === 'dark'
                          ? 'text-gray-200 hover:bg-gray-700'
                          : 'text-gray-700 hover:bg-gray-50 hover:text-blue-600'
                    }`}
                  >
                    {t('ComfyUI')}
                  </button>
                  <button
                    onClick={() => navigate('/workflow')}
                    className={`block w-full text-left px-4 py-2 ${
                      currentPath === 'workflow'
                        ? theme === 'dark'
                          ? 'bg-gray-700 text-white'
                          : 'bg-gray-100 text-blue-600'
                        : theme === 'dark'
                          ? 'text-gray-200 hover:bg-gray-700'
                          : 'text-gray-700 hover:bg-gray-50 hover:text-blue-600'
                    }`}
                  >
                    {t('AppBuilder')}
                  </button>
                </div>
              </div>
            </div>
          )}
          
          {/* User Controls */}
          <UserActions onEnterUserCenter={setUnselected?.bind(null, true)} theme={theme} />
          
          {/* Mobile Menu Toggle */}
          <div className="md:hidden">
            <button 
              className={`p-2 rounded-lg ${
                theme === 'dark' ? 'text-white/90 hover:bg-white/10' : 'text-gray-700 hover:bg-gray-50'
              }`}
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              {mobileMenuOpen 
                ? <CloseOutlined style={{ fontSize: 20 }} />
                : <MenuOutlined style={{ fontSize: 20 }} />
              }
            </button>
          </div>
        </div>
      </div>
      
      {/* Mobile Menu */}
      {mobileMenuOpen && (
        <div 
          className={`fixed inset-0 z-40 md:hidden`}
          onClick={() => setMobileMenuOpen(false)}
        >
          <div className="absolute inset-0 bg-black/50 backdrop-blur-sm" />
          
          <div 
            className={`absolute top-0 right-0 h-full w-3/4 max-w-xs py-14 px-4 overflow-y-auto transform transition-transform duration-300 ${
              theme === 'dark' 
                ? 'bg-gray-900/95 border-l border-white/10' 
                : 'bg-white/95 border-l border-gray-200/30'
            }`}
            style={{ animation: 'slideIn 0.3s ease forwards' }}
            onClick={e => e.stopPropagation()}
          >
            {/* 移动端导航菜单 */}
            <div className="flex flex-col space-y-1 max-h-[calc(100vh-120px)] overflow-y-auto pb-8">
              <div className="mb-4 pl-2">
                <div className={`font-medium text-sm uppercase tracking-wider mb-2 ${
                  theme === 'dark' ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  {t('Navigation')}
                </div>
              </div>
              
              {/* 主菜单项 */}
              {menuItems.map(item => (
                <button
                  key={item.key}
                  onClick={() => {
                    handleMenuClick(item.key, item.external, item.url);
                    setMobileMenuOpen(false);
                  }}
                  className={`w-full text-left px-4 py-2.5 rounded-lg transition-all duration-200 ${
                    currentPath === item.key || (item.key === '' && currentPath === '')
                      ? theme === 'dark'
                        ? 'bg-white/10 text-white font-medium'
                        : 'bg-blue-50 text-blue-700 font-medium'
                      : theme === 'dark'
                        ? 'text-gray-200 hover:bg-gray-800 hover:text-white'
                        : 'text-gray-800 hover:bg-gray-100 hover:text-blue-600'
                  }`}
                >
                  {item.label}
                </button>
              ))}
              
              {/* Dashboard相关菜单 - 仅在登录时显示 */}
              {userLoggedIn && (
                <>
                  <div className="mt-6 pt-4 border-t border-gray-700/30 mb-2">
                    <div className="mb-3 pl-2">
                      <div className={`font-medium text-sm uppercase tracking-wider ${
                        theme === 'dark' ? 'text-gray-400' : 'text-gray-500'
                      }`}>
                        {t('Dashboard')}
                      </div>
                    </div>
                  </div>
                  
                  <button
                    onClick={() => {
                      navigate('/comfyui');
                      setMobileMenuOpen(false);
                    }}
                    className={`w-full text-left px-4 py-2.5 rounded-lg transition-all duration-200 ${
                      currentPath === 'comfyui' 
                        ? theme === 'dark'
                          ? 'bg-white/10 text-white font-medium'
                          : 'bg-blue-50 text-blue-700 font-medium'
                        : theme === 'dark'
                          ? 'text-gray-200 hover:bg-gray-800 hover:text-white'
                          : 'text-gray-800 hover:bg-gray-100 hover:text-blue-600'
                    }`}
                  >
                    {t('ComfyUI')}
                  </button>
                  
                  <button
                    onClick={() => {
                      navigate('/workflow');
                      setMobileMenuOpen(false);
                    }}
                    className={`w-full text-left px-4 py-2.5 rounded-lg transition-all duration-200 ${
                      currentPath === 'workflow' 
                        ? theme === 'dark'
                          ? 'bg-white/10 text-white font-medium'
                          : 'bg-blue-50 text-blue-700 font-medium'
                        : theme === 'dark'
                          ? 'text-gray-200 hover:bg-gray-800 hover:text-white'
                          : 'text-gray-800 hover:bg-gray-100 hover:text-blue-600'
                    }`}
                  >
                    {t('AppBuilder')}
                  </button>
                </>
              )}
            </div>
    </div>
    </div>
      )}
    </>
  );
}

// 语言切换组件
export const HeaderLanguageSwitch = ({ theme = 'dark' }) => {
  const locale = useGlobalStore((state) => state.locale);
  const [ t, i18n ] = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale]);

  return (
    <div className="flex items-center">
      <div className={`flex rounded-full overflow-hidden ${
        theme === 'dark' ? 'bg-white/10' : 'bg-black/5'
      }`}>
        <button 
          className={`px-3 py-1 text-sm font-medium transition-all duration-200 ${
            locale === 'en-US' 
              ? `${theme === 'dark' ? 'bg-white text-black' : 'bg-gray-800 text-white'}` 
              : `${theme === 'dark' ? 'text-white/90' : 'text-gray-700'}`
          }`}
          onClick={() => useGlobalStore.setState({ locale: 'en-US' })}
        >
          EN
        </button>
        <button 
          className={`px-3 py-1 text-sm font-medium transition-all duration-200 ${
            locale === 'zh-CN' 
              ? `${theme === 'dark' ? 'bg-white text-black' : 'bg-gray-800 text-white'}` 
              : `${theme === 'dark' ? 'text-white/90' : 'text-gray-700'}`
          }`}
          onClick={() => useGlobalStore.setState({ locale: 'zh-CN' })}
        >
          中
        </button>
      </div>
    </div>
  );
};

// 用户操作组件
export const UserActions = (props) => {
  const { onEnterUserCenter, className, theme = 'dark' } = props;
  const user = useGlobalStore((state) => state.currentUser);
  const logout = useGlobalStore((state) => state.logout);
  const showLoginModal = useGlobalStore((state) => state.showLoginModal);
  const navigate = useNavigate();
  const [ t ] = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleSignUp = () => {
    showLoginModal();
  }

  const handleLogout = async () => {
    await logout();
  }

  const entryUserCenter = () => {
    navigate(`/space/${user?.publicId}`);
    onEnterUserCenter?.();
    setShowDropdown(false);
  }
  
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);
  
  if (!user?.publicId) {
    return (
      <div className={className}>
        <button 
          className={`px-4 py-1.5 rounded-md font-medium transition-all duration-200 text-sm ${
            theme === 'dark'
              ? 'bg-gradient-to-r from-blue-500 to-indigo-600 text-white hover:from-blue-600 hover:to-indigo-700 shadow-md shadow-indigo-500/20 hover:shadow-lg hover:shadow-indigo-500/30'
              : 'bg-blue-600 text-white hover:bg-blue-700 shadow-sm hover:shadow-md'
          }`}
          onClick={handleSignUp}
        >
          {t('SignIn')}
        </button>
      </div>
    );
  }
  
  return (
    <div className={`flex items-center ${className}`}>
      {/* 用户头像和下拉菜单 */}
      <div className="relative" ref={dropdownRef}>
        <div 
          className={`w-9 h-9 rounded-full overflow-hidden cursor-pointer flex items-center justify-center border-2 transition-all duration-200 ${
            theme === 'dark' 
              ? 'border-white/20 hover:border-white/40 hover:shadow-glow-light' 
              : 'border-gray-200 hover:border-gray-300 hover:shadow-md'
          }`}
          onClick={() => setShowDropdown(!showDropdown)}
        >
          {user.avatar ? (
            <img src={user.avatar} alt="avatar" className="w-full h-full object-cover" />
          ) : (
            <div className="w-full h-full bg-gradient-to-br from-blue-500 to-indigo-600 text-white flex items-center justify-center font-medium text-base">
              {user.email?.slice(0, 1).toUpperCase()}
            </div>
          )}
    </div>
        
        {showDropdown && (
          <div 
            className={`absolute top-full right-0 mt-2 w-48 bg-white rounded-lg shadow-xl overflow-hidden z-50`}
            style={{ animation: 'fadeIn 0.2s ease forwards' }}
          >
            <div className="py-2">
              <button 
                className="w-full px-4 py-2.5 text-left text-gray-700 hover:bg-blue-50 transition-colors duration-150 flex items-center"
                onClick={entryUserCenter}
              >
                <span className="w-5 h-5 flex items-center justify-center mr-2 text-blue-500">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                    <path fillRule="evenodd" d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z" clipRule="evenodd" />
                  </svg>
                </span>
              {t('UserCenter')}
              </button>
              <button 
                className="w-full px-4 py-2.5 text-left text-gray-700 hover:bg-blue-50 transition-colors duration-150 flex items-center"
                onClick={handleLogout}
              >
                <span className="w-5 h-5 flex items-center justify-center mr-2 text-blue-500">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                    <path fillRule="evenodd" d="M7.5 3.75A1.5 1.5 0 006 5.25v13.5a1.5 1.5 0 001.5 1.5h6a1.5 1.5 0 001.5-1.5V15a.75.75 0 011.5 0v3.75a3 3 0 01-3 3h-6a3 3 0 01-3-3V5.25a3 3 0 013-3h6a3 3 0 013 3V9A.75.75 0 0115 9V5.25a1.5 1.5 0 00-1.5-1.5h-6zm10.72 4.72a.75.75 0 011.06 0l3 3a.75.75 0 010 1.06l-3 3a.75.75 0 11-1.06-1.06l1.72-1.72H9a.75.75 0 010-1.5h10.94l-1.72-1.72a.75.75 0 010-1.06z" clipRule="evenodd" />
                  </svg>
                </span>
              {t('Logout')}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const FloatingActionButton = ({ setShowContactInfo }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState({ right: 24, bottom: 100 });
  const buttonRef = useRef(null);
  const isDragging = useRef(false);
  const dragStartPos = useRef({ x: 0, y: 0 });

  const handleDrag = (e) => {
    if (!isDragging.current) return;
    const deltaX = dragStartPos.current.x - e.clientX;
    const deltaY = dragStartPos.current.y - e.clientY;
    const newRight = Math.max(0, Math.min(window.innerWidth - 56, position.right + deltaX));
    const newBottom = Math.max(0, Math.min(window.innerHeight - 56, position.bottom + deltaY));
    setPosition({ right: newRight, bottom: newBottom });
    dragStartPos.current = { x: e.clientX, y: e.clientY };
  };

  useEffect(() => {
    const handleMouseUp = () => {
      isDragging.current = false;
    };

    document.addEventListener('mousemove', handleDrag);
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mousemove', handleDrag);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [position]);

  const startDrag = (e) => {
    e.preventDefault();
    isDragging.current = true;
    dragStartPos.current = { x: e.clientX, y: e.clientY };
  };

  const handleToggle = (e) => {
    e.stopPropagation();
    if (!isDragging.current) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <div
      ref={buttonRef}
      className={`${styles.floatingActionButton} ${isOpen ? styles.open : ''}`}
      style={{ 
        right: `${position.right}px`, 
        bottom: `${position.bottom}px`,
      }}
    >
      <div 
        className={styles.fabToggle} 
        onClick={handleToggle}
        onMouseDown={startDrag}
      >
        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
        </svg>
      </div>
      <div className={styles.fabMenu}>
        <Popover title={null} trigger="hover" placement="left" content={<GroupQrCode />}>
          <button className={`${styles.fabItem} ${styles.qrCode}`}>
            <QrcodeOutlined />
          </button>
        </Popover>
        <a href="https://github.com/jinchanz/antd-stable-diffusion-webui" target="_blank" rel="noopener noreferrer" className={`${styles.fabItem} ${styles.github}`}>
          <GithubOutlined />
        </a>
        <button className={`${styles.fabItem} ${styles.mail}`} onClick={() => setShowContactInfo(true)}>
          <MailOutlined />
        </button>
      </div>
    </div>
  );
};

const App: React.FC = () => {
  const locale = useGlobalStore((state) => state.locale);
  
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [showContactInfo, setShowContactInfo] = useState(false);
  const [unselected, setUnselected] = useState(false);
  const [ t, i18n ] = useTranslation();
  const negative = useNavigate();
  const location = useLocation();
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale]);


  const onMenuSelect = () => {
    setUnselected(false);
  }

  const goHome = () => {
    negative('/');
  }

  return (
    <Layout className="layout">
      <TransparentHeader 
        unselected={unselected} 
        setUnselected={setUnselected} 
        onMenuSelect={onMenuSelect} 
        theme={['/explore'].includes(location.pathname) ? 'dark' : 'light' as 'dark' | 'light'}
        className="bg-transparent fixed top-0 left-0 right-0 z-50"
      />
      <Content 
        ref={contentRef} 
        style={{ height: '100vh', overflowY: 'scroll', paddingTop: 64 }}
        onScroll={() => {
          const scrollPosition = contentRef?.current?.scrollTop ?? 0;
          setShowBackToTop(scrollPosition > 64);
        }}
      >
        <Outlet />
      </Content>
      {typeof window !== 'undefined' && ReactDOM.createPortal(
        <FloatingActionButton setShowContactInfo={setShowContactInfo} />,
        document.body
      )}
      { showBackToTop ? <FloatButton 
        icon={<VerticalAlignTopOutlined style={{ fontSize: 18 }} />}
        style={{ 
          right: 24, 
          bottom: 24,
          width: 56,
          height: 56,
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          color: '#007AFF',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          backdropFilter: 'blur(10px)',
          transition: 'all 0.3s ease',
        }}
        onClick={() => {
          contentRef?.current?.scrollTo?.({
            top: 0,
            behavior: 'smooth',
          });
        }} 
      /> : null }
      <Modal footer={false} title={
        <h1>{t('Contact')}</h1>
      } centered open={showContactInfo} onCancel={setShowContactInfo.bind(null, false)}>
        <div>
          <div className={styles.contactSection} style={{ display: 'flex', flexDirection: 'column', fontSize: 20, fontWeight: 400 }}>
            <span className={styles.contactLabel}>
              {t('Email')} 
            </span>
            <span className={styles.contactContent}>
              june@junemark.tech
            </span>
            <span className={styles.contactContent}>
              mark@junemark.tech
            </span>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default App;
