import moment from "moment";
import ImgCrop from "antd-img-crop";
import { useCallback, useEffect, useState } from "react";
import { Button, Upload, UploadProps, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

import { OssTOken } from "@/models/oss";
import Dragger from "antd/es/upload/Dragger";
import { useGlobalStore } from "@/store";

export interface ICommonUploaderProps extends UploadProps {
  value?: string;
  onChange?: (value: string) => void;
  enableCrop?: boolean;
  previewComponent?: (value: string, clear: () => void) => React.ReactNode;
  uploadButton?: (loading) => React.ReactNode;
  uploaderStyle?: React.CSSProperties;
  imageStyle?: '!xs'|'!medium'|''
  accept?: string;
}

const MAX_FILE_SIZE_LIMIT = 10 * 1024 * 1024;

const CommonUploader = (props: ICommonUploaderProps) => {
  const {value, onChange, enableCrop, previewComponent, uploadButton, uploaderStyle, imageStyle="!medium", ...uploaderProps} = props;
  const [loading, setLoading] = useState(false);
  const [ossToken, setOssToken] = useState<OssTOken>();
  const [messageApi, contextHolder] = message.useMessage();
  const currentUser = useGlobalStore((state) => state.currentUser);
  const showLoginModal = useGlobalStore((state) => state.showLoginModal);
  const now = moment(new Date());

  const init = useCallback(async () => {
    try {
      const tokenResponse = await fetch('/api/v1/oss/token');
      const initOssToken: OssTOken = (await tokenResponse.json()).data;
      setOssToken(initOssToken);
    } catch (error) {
      messageApi.error(error);
    }
  }, [messageApi]);

  useEffect(() => {
    init();
  }, [init, currentUser?.publicId]);

  const beforeUpload = async (file) => {
    if (!currentUser?.publicId) {
      showLoginModal(false);
      return false;
    }

    if (file.size > MAX_FILE_SIZE_LIMIT) {
      messageApi.error(`文件超过限制 ${MAX_FILE_SIZE_LIMIT/(1024*1024)}MB`);
      return false;
    }
    
    if (!ossToken) {
      console.error('ossToken is not ready');
      return false;
    }
    setLoading(true);
    const expire = Number(ossToken.expire) * 1000;
  
    if (expire < Date.now()) {
      await init();
    }
  
    file.url = `sd-online/images/${now.format('YYYY/MM/DD')}/${file.uid}${file.name.slice(file.name.lastIndexOf('.'))}`;
    return file;
  };
  
  const getExtraData: UploadProps['data'] = (file) => {
    file.url = `sd-online/images/${now.format('YYYY/MM/DD')}/${file.uid}${file.name.slice(file.name.lastIndexOf('.'))}`;
    return {
      key: file.url,
      OSSAccessKeyId: ossToken?.accessid,
      policy: ossToken?.policy,
      signature: ossToken?.signature,
      success_action_status: 200,
    };
  };

  const handleClear = () => {
    setLoading(false);
    onChange?.('');
  };

  const pureUpload = <Dragger
    name="file"
    showUploadList={false}
    action={ossToken?.host}
    beforeUpload={beforeUpload}
    data={getExtraData}
    {...uploaderProps}
    onChange={(file) => {
      if (file.file.status === 'done' && file.file.url) {
        onChange?.(`https://ablula.oss-accelerate.aliyuncs.com/${file.file.url}${imageStyle}`)
      }
    }}
  >
    { value 
      ? previewComponent?.(value, handleClear)
      : uploadButton?.(loading)
    }
  </Dragger>

  return <div style={{...uploaderStyle}}>
    {contextHolder}
    { enableCrop ? <ImgCrop cropShape="round">
        {pureUpload}
      </ImgCrop>
      :  value 
        ? previewComponent?.(value, handleClear)
        : pureUpload
    }
  </div>;
}

const FileUploader = (props: ICommonUploaderProps) => {
  const {value, onChange, enableCrop, previewComponent, uploadButton, uploaderStyle, imageStyle="!medium", ...uploaderProps} = props;
  const [loading, setLoading] = useState(false);
  const [ossToken, setOssToken] = useState<OssTOken>();
  const [messageApi, contextHolder] = message.useMessage();
  const currentUser = useGlobalStore((state) => state.currentUser);
  const showLoginModal = useGlobalStore((state) => state.showLoginModal);
  const now = moment(new Date());

  const init = useCallback(async () => {
    try {
      const tokenResponse = await fetch('/api/v1/oss/token');
      const initOssToken: OssTOken = (await tokenResponse.json()).data;
      setOssToken(initOssToken);
    } catch (error) {
      messageApi.error(error);
    }
  }, [messageApi]);

  useEffect(() => {
    init();
  }, [init, currentUser?.publicId]);

  const beforeUpload = async (file) => {
    if (!currentUser?.publicId) {
      showLoginModal(false);
      return false;
    }

    if (file.size > MAX_FILE_SIZE_LIMIT) {
      messageApi.error(`文件超过限制 ${MAX_FILE_SIZE_LIMIT/(1024*1024)}MB`);
      return;
    }
    
    if (!ossToken) {
      console.error('ossToken is not ready');
      return false;
    }
    setLoading(true);
    const expire = Number(ossToken.expire) * 1000;
  
    if (expire < Date.now()) {
      await init();
    }
  
    file.url = `sd-online/images/${now.format('YYYY/MM/DD')}/${file.uid}${file.name.slice(file.name.lastIndexOf('.'))}`;
    return file;
  };
  
  const getExtraData: UploadProps['data'] = (file) => {
    file.url = `sd-online/images/${now.format('YYYY/MM/DD')}/${file.uid}${file.name.slice(file.name.lastIndexOf('.'))}`;
    return {
      key: file.url,
      OSSAccessKeyId: ossToken?.accessid,
      policy: ossToken?.policy,
      signature: ossToken?.signature,
      success_action_status: 200,
    };
  };

  const handleClear = () => {
    setLoading(false);
    onChange?.('');
  };

  const pureUpload = <Dragger
    name="file"
    action={ossToken?.host}
    beforeUpload={beforeUpload}
    data={getExtraData}
    {...uploaderProps}
    onChange={(file) => {
      if (file.file.status === 'done' && file.file.url) {
        onChange?.(`https://ablula.oss-accelerate.aliyuncs.com/${file.file.url}${imageStyle}`)
      }
    }}
  >
    <Button type="text">上传文件</Button>
  </Dragger>

  return <div style={{...uploaderStyle}}>
    {contextHolder}
    { value 
        ? previewComponent?.(value, handleClear)
        : pureUpload
    }
  </div>;
}

export {
  FileUploader,
}

export default CommonUploader;