import { useEffect, useState, useRef } from 'react';
import { WorkflowItem } from "@/constants";
import { listWorkflowsByPage } from '@/services/Workflow';
import { useTranslation } from 'react-i18next';
import { useGlobalStore } from '@/store';
import WorkflowList from '../workflow/list';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';

export interface IWorkflowItemProps {
  item: WorkflowItem;
  onEdit?: (item?: WorkflowItem) => void;
  onlyPreview?: boolean;
}

// 定义分类和筛选数据结构
enum ContentType {
  IMAGE = 'image',
  WORKFLOW = 'workflow',
  APP = 'app'
}

enum FilterType {
  ALL = 'all',
  LIKES = 'likes',
  OUR_PICKS = 'ourPicks',
  TRENDING = 'trending'
}

enum SortType {
  LATEST = 'latest',
  OLDEST = 'oldest',
  MOST_LIKED = 'mostLiked',
  MOST_VIEWED = 'mostViewed'
}

// 在文件开头添加默认的翻译对象
const defaultTranslations = {
  en: {
    // 通用
    ComingSoon: 'Coming Soon',
    StayTuned: 'Stay tuned for exciting updates!',
    Search: 'Search...',
    LoadMore: 'Load More',
    Explore: 'Explore',
    ExploreDescription: 'Discover amazing images, workflows and applications',
    
    // 内容类型
    IMAGE: 'Image',
    Workflow: 'Workflow',
    App: 'App',
    
    // 筛选选项
    All: 'All',
    Likes: 'Likes',
    OurPicks: 'Our Picks',
    Trending: 'Trending',
    
    // 按钮和操作
    RunWorkflow: 'Run Workflow',
    ViewDetails: 'View Details',
    View: 'View',
    
    // 空状态提示
    NoImagesFound: 'No Images Found',
    NoImagesDescription: 'No images match your search criteria.',
    NoWorkflowsFound: 'No Workflows Found',
    NoWorkflowsDescription: 'No workflows match your search criteria.',
    NoAppsFound: 'No Apps Found',
    NoAppsDescription: 'No apps match your search criteria.',
    
    // 工作流示例
    FluxIPAdapter: {
      title: 'FLUX IP-Adapter | ImageGen with Style Adapter',
      description: 'Adapt pre-trained models to specific image styles for stunning 512x512 and 1024x1024 outputs.'
    },
    ComfyUIDance: {
      title: 'ComfyUI VidVid Dance Transfer',
      description: 'Transfers the motion and style from a source video onto a target image or object.'
    },
    FluxInpainting: {
      title: 'FLUX Inpainting | Seamless Image Editing',
      description: 'Effortlessly fill, remove, and refine images, seamlessly integrating new content.'
    },
    FluxGeneration: {
      title: 'FLUX | A New Art Image Generation',
      description: 'A new image generation model developed by Black Forest Labs'
    },
    
    // 应用示例
    ImageEnhancer: {
      title: 'Image Enhancer',
      description: 'One-click image quality optimization'
    },
    StyleTransfer: {
      title: 'Style Transfer',
      description: 'Apply artistic styles to your images'
    },
    PortraitBeautification: {
      title: 'Portrait Beautification',
      description: 'Smart portrait photo retouching'
    }
  },
  zh: {
    // 通用
    ComingSoon: '即将推出',
    StayTuned: '敬请期待更多精彩内容！',
    Search: '搜索...',
    LoadMore: '加载更多',
    Explore: '探索',
    ExploreDescription: '发现精彩图片、工作流和应用',
    
    // 内容类型
    IMAGE: '图片',
    Workflow: '工作流',
    App: '应用',
    
    // 筛选选项
    All: '全部',
    Likes: '喜欢',
    OurPicks: '精选',
    Trending: '热门',
    
    // 按钮和操作
    RunWorkflow: '运行工作流',
    ViewDetails: '查看详情',
    View: '查看',
    
    // 空状态提示
    NoImagesFound: '未找到图片',
    NoImagesDescription: '没有符合搜索条件的图片。',
    NoWorkflowsFound: '未找到工作流',
    NoWorkflowsDescription: '没有符合搜索条件的工作流。',
    NoAppsFound: '未找到应用',
    NoAppsDescription: '没有符合搜索条件的应用。',
    
    // 工作流示例
    FluxIPAdapter: {
      title: 'FLUX IP-Adapter | 图像风格适配器',
      description: '将预训练模型适配到特定图像风格，生成令人惊叹的512x512和1024x1024输出。'
    },
    ComfyUIDance: {
      title: 'ComfyUI 舞蹈迁移',
      description: '将源视频的动作和风格迁移到目标图像或对象上。'
    },
    FluxInpainting: {
      title: 'FLUX 图像修复 | 无缝图像编辑',
      description: '轻松填充、移除和优化图像，无缝集成新内容。'
    },
    FluxGeneration: {
      title: 'FLUX | 新一代艺术图像生成',
      description: '由Black Forest Labs开发的新一代图像生成模型'
    },
    
    // 应用示例
    ImageEnhancer: {
      title: '图像增强器',
      description: '一键优化图像质量'
    },
    StyleTransfer: {
      title: '风格迁移',
      description: '将艺术风格应用到图片'
    },
    PortraitBeautification: {
      title: '人像美化',
      description: '智能修饰人像照片'
    }
  }
};

// 封装图片卡片组件
const ImageCard = ({ 
  image, 
  className = '', 
  withActions = true
}: { 
  image: {
    id: string;
    url: string;
    username: string;
    thumbnail?: string;
  },
  className?: string,
  withActions?: boolean
}) => {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <motion.div 
      className={`relative group overflow-hidden rounded-md ${className}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      layoutId={`image-${image.id}`}
    >
      <img 
        src={image.thumbnail || image.url} 
        alt={`Image by ${image.username}`}
        className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
        loading="lazy"
      />
      
      {withActions && (
        <>
          {/* Username overlay */}
          <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/80 to-transparent p-3 transition-opacity duration-300">
            <div className="flex items-center justify-between">
              <span className="text-white text-sm font-medium truncate">{image.username}</span>
              
              <div className="flex items-center space-x-2">
                <button className="text-white hover:text-pink-400 transition-colors">
                  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                    <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z" />
                  </svg>
                </button>
                <button className="text-white hover:text-blue-400 transition-colors">
                  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4M7 10l5 5 5-5M12 15V3" />
                  </svg>
                </button>
                <button className="text-white hover:text-green-400 transition-colors">
                  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                    <path d="M8.59 13.51l-4.83 5.17a2 2 0 0 0 1.42 3.43H21a2 2 0 0 0 2-2v-14a2 2 0 0 0-2-2h-7" />
                    <path d="M16 8l-8 8" />
                    <path d="M21 11h-7v-7" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </motion.div>
  );
};

// 封装通用的应用卡片组件
const AppCard = ({ 
  cover, 
  icon, 
  title, 
  description, 
  viewButton, 
  onClick 
}: { 
  cover: string; 
  icon?: string; 
  title: string; 
  description: string; 
  viewButton: string;
  onClick: () => void; 
}) => {
  const [hover, setHover] = useState(false);
  
  return (
    <motion.div
      whileHover={{ y: -5 }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ 
        duration: 0.3,
        y: { type: "spring", stiffness: 300, damping: 30 }
      }}
      className="relative overflow-hidden bg-gradient-to-b from-gray-800/50 to-gray-900/50 rounded-xl border border-gray-800/50 cursor-pointer transform-gpu backdrop-blur-sm"
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {/* 封面图 */}
      <div className="relative aspect-[16/9] overflow-hidden">
        <img 
          src={cover} 
          alt={title} 
          className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
          loading="lazy" 
        />
        <div className={`absolute inset-0 bg-black/60 flex items-center justify-center transition-opacity duration-300 backdrop-blur-sm ${
          hover ? 'opacity-100' : 'opacity-0'
        }`}>
          <button 
            className="px-4 py-2 bg-white/10 hover:bg-white/20 border border-white/20 rounded-lg text-white font-medium transition-transform transform hover:scale-105"
            onClick={(e) => {
              e.stopPropagation();
              onClick();
            }}
          >
            {viewButton}
          </button>
        </div>
      </div>
      
      {/* 信息区 */}
      <div className="p-4">
        <div className="flex items-start gap-3">
          {icon && (
            <div className="flex-shrink-0">
              <img 
                src={icon} 
                alt="icon" 
                className="w-10 h-10 rounded-full ring-2 ring-gray-700/50"
              />
            </div>
          )}
          <div className="flex-1 min-w-0">
            <h3 className="text-lg font-medium text-white truncate transition-colors group-hover:text-indigo-300">
              {title}
            </h3>
            <p className="mt-1 text-sm text-gray-400 line-clamp-2 transition-colors group-hover:text-gray-300">
              {description || '暂无描述'}
            </p>
          </div>
        </div>
      </div>
      
      {/* 渐变边框效果 */}
      <div className="absolute inset-0 border border-transparent rounded-xl bg-gradient-to-r from-indigo-500/0 via-indigo-500/10 to-purple-500/0 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
    </motion.div>
  );
};

// 封装工作流卡片组件
const WorkflowCard = ({ 
  workflow,
  onRun,
  onViewDetails 
}: { 
  workflow: {
    id: string;
    title: string;
    description: string;
    cover: string;
  };
  onRun: () => void;
  onViewDetails: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <div className="relative bg-gray-800/50 rounded-lg overflow-hidden group">
      <img 
        src={workflow.cover} 
        alt={workflow.title}
        className="w-full aspect-[16/9] object-cover"
      />
      <div className="p-4">
        <h3 className="text-lg font-semibold text-white mb-2">{workflow.title}</h3>
        <p className="text-sm text-gray-400 line-clamp-2">{workflow.description}</p>
        <div className="flex gap-3 mt-4">
          <button
            onClick={onRun}
            className="flex-1 px-4 py-2 bg-purple-600 hover:bg-purple-700 text-white rounded-lg transition-colors flex items-center justify-center gap-2"
          >
            <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <path d="M5 3l14 9-14 9V3z" />
            </svg>
            {t('RunWorkflow')}
          </button>
          <button
            onClick={onViewDetails}
            className="flex-1 px-4 py-2 bg-gray-600 hover:bg-gray-700 text-white rounded-lg transition-colors flex items-center justify-center gap-2"
          >
            <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              <path d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
            </svg>
            {t('ViewDetails')}
          </button>
        </div>
      </div>
    </div>
  );
};

// 封装即将推出的蒙层组件
const ComingSoonOverlay = () => {
  const { t } = useTranslation();
  return (
    <div className="absolute inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center z-20">
      <div className="text-center">
        <div className="inline-flex items-center justify-center w-20 h-20 rounded-full bg-white/10 mb-6">
          <svg className="w-10 h-10 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
          </svg>
        </div>
        <h3 className="text-2xl font-bold text-white mb-2">{t('ComingSoon')}</h3>
        <p className="text-gray-400">{t('StayTuned')}</p>
      </div>
    </div>
  );
};

// 主页面组件
const ExplorePage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = useGlobalStore((state) => state.currentUser);
  
  // 从URL参数获取初始状态
  const queryParams = new URLSearchParams(location.search);
  const initialType = (queryParams.get('type') as ContentType) || ContentType.APP;
  const initialFilter = (queryParams.get('filter') as FilterType) || FilterType.ALL;
  
  // 状态管理
  const [contentType, setContentType] = useState<ContentType>(initialType);
  const [filterType, setFilterType] = useState<FilterType>(initialFilter);
  const [sortType, setSortType] = useState<SortType>(SortType.LATEST);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [showSortOptions, setShowSortOptions] = useState(false);
  
  // 数据状态
  const [workflowList, setWorkflowList] = useState<WorkflowItem[]>([]);
  const [imageList, setImageList] = useState<any[]>([]);
  const [appList, setAppList] = useState<any[]>([]);
  
  // 更新URL参数以便共享和书签
  useEffect(() => {
    const params = new URLSearchParams();
    params.set('type', contentType);
    params.set('filter', filterType);
    
    const newUrl = `${location.pathname}?${params.toString()}`;
    window.history.replaceState({}, '', newUrl);
  }, [contentType, filterType, location.pathname]);
  
  // 获取工作流数据
  const fetchWorkflows = async (resetPage: boolean = false) => {
    if (isLoading) return;
    
    try {
      setIsLoading(true);
      
      // 重置页面时从第一页开始
      const currentPage = resetPage ? 1 : pageNum;
      if (resetPage) {
        setPageNum(1);
        setWorkflowList([]);
      }
      
      // 构建查询参数
      const queryParams: any = { isDefault: true };
      
      // 根据筛选条件添加参数
      if (filterType === FilterType.LIKES) {
        queryParams.sort = 'likes';
      } else if (filterType === FilterType.TRENDING) {
        queryParams.sort = 'trending';
      }
      
      // 添加搜索条件
      if (searchTerm) {
        queryParams.keyword = searchTerm;
      }
      
      const response = await listWorkflowsByPage(queryParams, currentPage, 12);
      
      const formattedList = response.data?.list?.map(item => ({
          ...item,
          contentTpl: JSON.parse(item?.contentTpl || '{}'),
          paramTpl: JSON.parse(item?.paramTpl || '{}'),
          outputTpl: JSON.parse(item?.outputTpl || '{}'),
      })) || [];
      
      // 更新列表和分页状态
      setWorkflowList(prev => {
        if (resetPage) {
          return formattedList;
        } else {
          // 使用 publicId 作为唯一标识进行去重
          const existingIds = new Set(prev.map(item => item.publicId));
          const newUniqueItems = formattedList.filter(item => !existingIds.has(item.publicId));
          return [...prev, ...newUniqueItems];
        }
      });
      setHasMore(formattedList.length === 12);
      
      if (!resetPage) {
        setPageNum(prev => prev + 1);
      }
    } catch (error) {
      // 显示错误通知
      console.error(t('FetchFailed'), error.message);
    } finally {
      setIsLoading(false);
    }
  };
  
  // 获取图片数据（示例数据，实际应调用相应API）
  const fetchImages = async () => {
    // 模拟加载数据（实际开发中应替换为API调用）
    setImageList([
      {
        id: '1',
        url: 'https://picsum.photos/seed/1/800/600',
        thumbnail: 'https://picsum.photos/seed/1/800/600',
        username: 'CraftedArt',
        width: 800,
        height: 600
      },
      {
        id: '2',
        url: 'https://picsum.photos/seed/2/600/800',
        thumbnail: 'https://picsum.photos/seed/2/600/800',
        username: 'Photography_Pro',
        width: 600,
        height: 800
      },
      {
        id: '3',
        url: 'https://picsum.photos/seed/3/900/500',
        thumbnail: 'https://picsum.photos/seed/3/900/500',
        username: 'DesignMaster',
        width: 900,
        height: 500
      },
      {
        id: '4',
        url: 'https://picsum.photos/seed/4/800/800',
        thumbnail: 'https://picsum.photos/seed/4/800/800',
        username: 'CreativeSpirit',
        width: 800,
        height: 800
      },
      {
        id: '5',
        url: 'https://picsum.photos/seed/5/600/400',
        thumbnail: 'https://picsum.photos/seed/5/600/400',
        username: 'UrbanShots',
        width: 600,
        height: 400
      },
      {
        id: '6',
        url: 'https://picsum.photos/seed/6/500/750',
        thumbnail: 'https://picsum.photos/seed/6/500/750',
        username: 'NatureExplorer',
        width: 500,
        height: 750
      },
      {
        id: '7',
        url: 'https://picsum.photos/seed/7/850/550',
        thumbnail: 'https://picsum.photos/seed/7/850/550',
        username: 'DigitalDreamer',
        width: 850,
        height: 550
      },
      {
        id: '8',
        url: 'https://picsum.photos/seed/8/700/900',
        thumbnail: 'https://picsum.photos/seed/8/700/900',
        username: 'ArtisticSoul',
        width: 700,
        height: 900
      },
      {
        id: '9',
        url: 'https://picsum.photos/seed/9/800/450',
        thumbnail: 'https://picsum.photos/seed/9/800/450',
        username: 'ColorMaster',
        width: 800,
        height: 450
      },
      {
        id: '10',
        url: 'https://picsum.photos/seed/10/600/600',
        thumbnail: 'https://picsum.photos/seed/10/600/600',
        username: 'LightCatcher',
        width: 600,
        height: 600
      }
    ]);
  };
  
  // 获取应用数据，合并工作流数据
  const fetchApps = async () => {
    // 获取工作流数据
    await fetchWorkflows(true);
    
    // 设置示例应用数据（实际开发时应替换为API调用）
    setAppList([
      {
        id: '1',
        title: t('ImageEnhancer.title'),
        description: t('ImageEnhancer.description'),
        cover: 'https://picsum.photos/seed/11/600',
        icon: 'https://picsum.photos/seed/11/40',
        url: '/app/1'
      },
      {
        id: '2',
        title: t('StyleTransfer.title'),
        description: t('StyleTransfer.description'),
        cover: 'https://picsum.photos/seed/12/600',
        icon: 'https://picsum.photos/seed/12/40',
        url: '/app/2'
      },
      {
        id: '3',
        title: t('PortraitBeautification.title'),
        description: t('PortraitBeautification.description'),
        cover: 'https://picsum.photos/seed/13/600',
        icon: 'https://picsum.photos/seed/13/40',
        url: '/app/3'
      }
    ]);
  };
  
  // 初始加载和切换分类时加载数据
  useEffect(() => {
    // 重置分页
    setPageNum(1);
    setHasMore(true);
    
    if (contentType === ContentType.APP) {
      fetchApps();
    } else if (contentType === ContentType.IMAGE) {
      fetchImages();
    }
  }, [contentType, filterType, sortType]);
  
  // 监听搜索框变化，带防抖
  useEffect(() => {
    const timer = setTimeout(() => {
      if (contentType === ContentType.APP) {
        fetchApps();
      } else if (contentType === ContentType.IMAGE) {
        fetchImages();
      }
    }, 500); // 500ms防抖
    
    return () => clearTimeout(timer);
  }, [searchTerm]);
  
  // 监听滚动加载更多
  useEffect(() => {
    const handleScroll = () => {
      if (isLoading || !hasMore) return;
      
      const scrollHeight = document.documentElement.scrollHeight;
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      const clientHeight = document.documentElement.clientHeight;
      
      // 当滚动到底部附近时加载更多
      if (scrollHeight - scrollTop - clientHeight < 200) {
        if (contentType === ContentType.APP) {
          fetchWorkflows();
        }
      }
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isLoading, hasMore, contentType]);
  
  // 处理排序选择
  const handleSortChange = (newSortType: SortType) => {
    setSortType(newSortType);
    setShowSortOptions(false);
  };
  
  // 渲染当前内容类型的内容列表
  const renderContentList = () => {
    switch (contentType) {
      case ContentType.IMAGE:
        return (
          <div className="relative">
            <ComingSoonOverlay />
            <div className="w-full">
              {imageList.length > 0 ? (
                <div className="grid grid-cols-1 md:grid-cols-4 auto-rows-auto gap-2">
                  {/* 按照原型图中的布局，我们使用不同的网格位置 */}
                  <ImageCard image={imageList[0]} className="row-span-2 col-span-2" />
                  <ImageCard image={imageList[1]} className="row-span-1 col-span-2" />
                  <ImageCard image={imageList[2]} className="row-span-2 col-span-1" />
                  <ImageCard image={imageList[3]} className="row-span-2 col-span-1" />
                  <ImageCard image={imageList[4]} className="row-span-2 col-span-2" />
                  <ImageCard image={imageList[5]} className="row-span-1 col-span-1" />
                  <ImageCard image={imageList[6]} className="row-span-1 col-span-1" />
                  <ImageCard image={imageList[7]} className="row-span-1 col-span-1" />
                  <ImageCard image={imageList[8]} className="row-span-1 col-span-1" />
                  <ImageCard image={imageList[9]} className="row-span-1 col-span-1" />
                </div>
              ) : !isLoading ? (
                <div className="col-span-full py-20 text-center">
                  <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gray-800/50 mb-6">
                    <svg className="w-8 h-8 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </div>
                  <h3 className="text-xl font-semibold text-gray-200 mb-3">
                    {t('NoImagesFound')}
                  </h3>
                  <p className="text-gray-400 max-w-md mx-auto">
                    {t('NoImagesDescription')}
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        );
      
      case ContentType.WORKFLOW:
        return (
          <div className="relative">
            <ComingSoonOverlay />
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {[
                {
                  id: '1',
                  title: t('FluxIPAdapter.title'),
                  description: t('FluxIPAdapter.description'),
                  cover: 'https://picsum.photos/seed/8/700/900'
                },
                {
                  id: '2',
                  title: t('ComfyUIDance.title'),
                  description: t('ComfyUIDance.description'),
                  cover: 'https://picsum.photos/seed/8/700/900'
                },
                {
                  id: '3',
                  title: t('FluxInpainting.title'),
                  description: t('FluxInpainting.description'),
                  cover: 'https://picsum.photos/seed/8/700/900'
                },
                {
                  id: '4',
                  title: t('FluxGeneration.title'),
                  description: t('FluxGeneration.description'),
                  cover: 'https://picsum.photos/seed/8/700/900'
                }
              ].map(workflow => (
                <WorkflowCard
                  key={workflow.id}
                  workflow={workflow}
                  onRun={() => {/* 处理运行工作流 */}}
                  onViewDetails={() => {/* 处理查看详情 */}}
                />
              ))}
            </div>
          </div>
        );
      
      case ContentType.APP:
        return (
          <div>
            {/* 工作流应用列表 */}
            {workflowList.length > 0 && (
              <div className="mb-12">
                <WorkflowList onlyPreview list={workflowList} />
              </div>
            )}
            
            
            {/* 空状态显示 */}
            {!isLoading && workflowList.length === 0 && appList.length === 0 && (
              <div className="py-20 text-center">
                <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gray-800/50 mb-6">
                  <svg className="w-8 h-8 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
                  </svg>
                </div>
                <h3 className="text-xl font-semibold text-gray-200 mb-3">
                  {t('NoAppsFound')}
                </h3>
                <p className="text-gray-400 max-w-md mx-auto">
                  {t('NoAppsDescription')}
                </p>
              </div>
            )}
          </div>
        );
        
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-slate-950" style={{ marginTop: -64 }}>
      {/* 背景层 */}
      <div className="fixed inset-0 pointer-events-none">
        {/* 网格背景 */}
        <div 
          className="absolute inset-0 opacity-50"
          style={{
            backgroundImage: `
              linear-gradient(to right, rgba(79, 79, 79, 0.18) 1px, transparent 1px),
              linear-gradient(to bottom, rgba(79, 79, 79, 0.18) 1px, transparent 1px)
            `,
            backgroundSize: '14px 24px',
            maskImage: 'radial-gradient(ellipse 80% 50% at 50% 0%, black 70%, transparent 110%)',
            WebkitMaskImage: 'radial-gradient(ellipse 80% 50% at 50% 0%, black 70%, transparent 110%)',
          }}
        />
        
        {/* 渐变背景 */}
        <div 
          className="absolute inset-0 bg-gradient-to-b from-indigo-500/20 via-transparent to-transparent opacity-30"
          style={{
            transform: 'skewY(-12deg) translateY(-10%)',
          }}
        />
      </div>

      {/* 内容主体 */}
      <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-32 pb-16">
        {/* 头部标题区域 */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-12 space-y-6"
        >
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-white to-gray-300">
            {t('Explore')}
          </h1>
          <p className="text-xl text-gray-400 max-w-2xl mx-auto">
            {t('ExploreDescription')}
          </p>
        </motion.div>
        
        {/* 分类和筛选区域 */}
        <div className="backdrop-blur-sm bg-white/5 rounded-2xl p-6 mb-8 shadow-xl">
          {/* 分类切换按钮组 */}
          <div className="flex flex-col md:flex-row md:items-center justify-between gap-4 mb-6">
            <div className="flex overflow-x-auto pb-2 md:pb-0 gap-2">
              <button
                className={`px-4 py-2 rounded-md font-medium transition-colors ${
                  contentType === ContentType.APP
                    ? 'bg-blue-400 text-white'
                    : 'bg-gray-200/10 text-gray-300 hover:bg-white/10'
                }`}
                onClick={() => setContentType(ContentType.APP)}
              >
                {t('explore.contentTypes.apps')}
              </button>
              <button
                className={`px-4 py-2 rounded-md font-medium transition-colors ${
                  contentType === ContentType.IMAGE 
                    ? 'bg-blue-400 text-white'
                    : 'bg-gray-200/10 text-gray-300 hover:bg-white/10'
                }`}
                onClick={() => setContentType(ContentType.IMAGE)}
              >
                {t('explore.contentTypes.images')}
              </button>
              <button
                className={`px-4 py-2 rounded-md font-medium transition-colors ${
                  contentType === ContentType.WORKFLOW
                    ? 'bg-blue-400 text-white'
                    : 'bg-gray-200/10 text-gray-300 hover:bg-white/10'
                }`}
                onClick={() => setContentType(ContentType.WORKFLOW)}
              >
                {t('explore.contentTypes.workflows')}
              </button>
            </div>
            
            {/* 搜索框 */}
            <div className="relative">
              <input
                type="text"
                className="w-full md:w-64 pl-10 pr-4 py-2 bg-white/10 border border-white/10 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                placeholder={t('Search')}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <svg className="absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
              {searchTerm && (
                <button
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-white"
                  onClick={() => setSearchTerm('')}
                >
                  <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              )}
            </div>
          </div>
          
          {/* 筛选选项 - 为所有类型显示 */}
          <div className="flex flex-wrap gap-2">
            <button
              className={`px-4 py-1.5 rounded-full text-sm font-medium transition-colors ${
                filterType === FilterType.ALL
                  ? 'bg-white/20 text-white'
                  : 'bg-transparent text-gray-400 hover:text-white hover:bg-white/10'
              }`}
              onClick={() => setFilterType(FilterType.ALL)}
            >
              {t('explore.filters.all')}
            </button>
            <button
              className={`px-4 py-1.5 rounded-full text-sm font-medium transition-colors ${
                filterType === FilterType.LIKES
                  ? 'bg-white/20 text-white'
                  : 'bg-transparent text-gray-400 hover:text-white hover:bg-white/10'
              }`}
              onClick={() => setFilterType(FilterType.LIKES)}
            >
              {t('explore.filters.likes')}
            </button>
            <button
              className={`px-4 py-1.5 rounded-full text-sm font-medium transition-colors ${
                filterType === FilterType.OUR_PICKS
                  ? 'bg-white/20 text-white'
                  : 'bg-transparent text-gray-400 hover:text-white hover:bg-white/10'
              }`}
              onClick={() => setFilterType(FilterType.OUR_PICKS)}
            >
              {t('explore.filters.ourPicks')}
            </button>
            <button
              className={`px-4 py-1.5 rounded-full text-sm font-medium transition-colors ${
                filterType === FilterType.TRENDING
                  ? 'bg-white/20 text-white'
                  : 'bg-transparent text-gray-400 hover:text-white hover:bg-white/10'
              }`}
              onClick={() => setFilterType(FilterType.TRENDING)}
            >
              {t('explore.filters.trending')}
            </button>
          </div>
        </div>

        {/* 内容列表 */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="backdrop-blur-sm bg-white/5 rounded-2xl p-6 shadow-2xl"
        >
          {renderContentList()}
          
          {/* 加载状态 */}
          {isLoading && (
            <div className="flex justify-center items-center py-12">
              <div className="relative">
                <div className="w-12 h-12 rounded-full border-t-4 border-b-4 border-indigo-500 animate-spin" />
                <div className="absolute inset-0 w-12 h-12 rounded-full border-t-4 border-b-4 border-violet-500 animate-spin blur-sm opacity-50" />
              </div>
            </div>
          )}
          
          {/* 加载更多按钮 */}
          {hasMore && !isLoading && contentType === ContentType.APP && (
            <div className="flex justify-center mt-8">
              <button
                onClick={() => fetchWorkflows()}
                className="px-6 py-2 bg-indigo-600 hover:bg-indigo-700 text-white rounded-lg transition-colors shadow-md"
              >
                {t('LoadMore')}
              </button>
            </div>
          )}
        </motion.div>
      </div>
    </div>
  );
};

export default ExplorePage;